import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ValidatableForm from "./validatable-form";
import { CHALLENGE_PROP_TYPES, USER_PROP_TYPES } from "./constants/prop-types";
import ToggleSwitch from "./toggle-switch";
import DateTimePicker from "./date-time-picker";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import MediaUploader from "./media-uploader";

const ChallengeForm = (props) => {
  const {
    authenticityToken,
    action,
    exampleCertificateMedia,
    exampleCertificateMediaType,
    environment,
    mediaUploadParams,
    mediaUploadSignature,
    prizeMediaUploadParams,
    prizeMediaUploadSignature,
    defaultMedia,
    currentUserIsAdmin
  } = props;

  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [showLearnMore, setShowLearnMore] = useState(false);
  const [prize, setPrize] = useState(props.challenge.prize);
  const [sendingCertificates, setSendingCertificates] = useState(
    props.challenge.sendCertificates
  );
  const [certificatesForAll, setCertificatesForAll] = useState(
    props.challenge.certificatesForAllSubmissions
  )

  const certificateDescription =
    "A personalized digital Certificate is sent by email to every member of the Challenge who submits at least one proof of completion. The Certificate includes the member’s name, Challenge name, completion date, and the Challenge URL showing the member’s participation in the actual Challenge. This URL verification helps to certify their participation for record-keeping. The Challenge Leader’s name is automatically entered in script type along with Dr. Glenn Thomas’s signature that authenticates the Certificate as an original, valid, and Flockittome.com copyrighted Certificate of accomplishment.";

  useEffect(() => {
    if (sendingCertificates) {
      if (!prize) {
        setPrize(certificateDescription);
      }
    } else {
      if (prize === certificateDescription) {
        setPrize(props.challenge.prize);
      }
    }
  }, [sendingCertificates]);

  useEffect(() => {
    if (sendingCertificates) {
      setCertificatesForAll(true);
    } else {
      setCertificatesForAll(false);
    }
  }, [sendingCertificates])

  const challengeHasStarted = !!props.challenge.startDate && (new Date(props.challenge.startDate) < new Date());
  const canUpdateChallenge = (currentUserIsAdmin === "true") || !challengeHasStarted;

  return (
    <>
      <ValidatableForm
        action={action}
        authenticityToken={authenticityToken}
        id="challenge-form"
        onSuccess={(challenge) => (location.href = challenge.path)}
        onError={(errors) => setErrors(errors)}
        setSubmitting={setSubmitting}
        method={props.challenge.id ? "patch" : "post"}
        className="challenge-form validatable-form"
        encType="multipart/form-data"
      >
        <h2>{props.challenge.id ? "Edit" : "Create a"} Challenge</h2>

        <input
          type="hidden"
          name="challenge[number]"
          value={mediaUploadParams.fields.number}
        />

        <div className="form-group">
          <MediaUploader
            selectedLabel={(mediaType) => `Challenge ${mediaType}`}
            selectLabel="Upload an Image or Video For This Challenge"
            name="challenge[challenge_media]"
            id="challenge_challenge_media"
            mediaTypeFieldName="challenge[challenge_media_type]"
            environment={environment}
            processedMediaData={props.challenge.challengeMedia}
            mediaType={props.challenge.challengeMediaType}
            mediaUploadParams={mediaUploadParams}
            mediaUploadSignature={mediaUploadSignature}
            defaultMedia={defaultMedia}
            disabled={!canUpdateChallenge}
          />
          <div className="invalid-feedback">
            Please select the challenge image to upload.
          </div>
        </div>

        <div className="form-group">
          <div className="d-flex toggle-switch">
            <label htmlFor="challenge_public" className="form-check-label">
              Is this a public challenge?
            </label>
            <ToggleSwitch
              id="challenge_public"
              name="challenge[public]"
              checked={props.challenge.public}
              onLabel="Public"
              offLabel="Private"
              disabled={!canUpdateChallenge}
            />
          </div>
          <small className="form-text text-muted">
            As a reminder, anyone can join a flock to a public challenge.
          </small>
        </div>

        <div className="form-group">
          <label htmlFor="challenge_name">Challenge Name</label>
          <input
            type="text"
            id="challenge_name"
            name="challenge[name]"
            className="form-control"
            defaultValue={props.challenge.name}
            disabled={!canUpdateChallenge}
            required
          />
          <div className="invalid-feedback">
            Please provide a name for this challenge.
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="challenge_description">Challenge Description</label>
          <textarea
            id="challenge_description"
            name="challenge[description]"
            className="form-control"
            defaultValue={props.challenge.description}
            disabled={!canUpdateChallenge}
            required
          />
          <div className="invalid-feedback">
            Please provide a description for this challenge.
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-6 pr-md-3">
            {/*TODO: make sure that we show the correct validation errors (eg start after finish)*/}
            <DateTimePicker
              value={props.challenge.startDate}
              name="challenge[start_date]"
              id="challenge_start_date"
              labelTime="Start Time"
              labelDate="Start Date"
              errorClassName={errors.start_date && "is-invalid"}
              errorMessage={
                errors.start_date
                  ? errors.start_date.join(" ")
                  : "Please provide the challenge's start date."
              }
              required
            />
          </div>

          <div className="form-group col-md-6 pl-md-3">
            <DateTimePicker
              value={props.challenge.endDate}
              name="challenge[end_date]"
              id="challenge_end_date"
              labelTime="End Time"
              labelDate="End Date"
              errorClassName={errors.end_date && "is-invalid"}
              errorMessage={
                errors.end_date
                  ? errors.end_date.join(" ")
                  : "Please provide the challenge's ending date."
              }
              required
            />
          </div>
        </div>

        <div className="form-group">
          <div className="d-flex toggle-switch">
            <label
              htmlFor="challenge_allow_joins_after_start"
              className="form-check-label"
            >
              Allow joins after start date?
            </label>
            <ToggleSwitch
              id="challenge_allow_joins_after_start"
              name="challenge[allow_joins_after_start]"
              checked={props.challenge.allowJoinsAfterStart}
              onLabel="Yes"
              offLabel="No"
              disabled={!canUpdateChallenge}
            />
          </div>
        </div>

        <h3>Define the Prize</h3>

        <div className="form-group">
          <div className="d-flex align-items-center">
            <label
              htmlFor="challenge_send_certificates"
              className="form-check-label"
            >
              Send digital certificate prizes?
            </label>
            <div className="toggle-switch" style={{ width: "6em", "marginRight": "1em" }}>
              <ToggleSwitch
                id="challenge_send_certificates"
                name="challenge[send_certificates]"
                checked={props.challenge.sendCertificates}
                onLabel="Yes"
                offLabel="No"
                onChange={(checked) => setSendingCertificates(checked)}
                disabled={!canUpdateChallenge}
              />
            </div>
            <div>
              <button
                type="button"
                className="btn btn-outline-dark ml-3"
                onClick={() => setShowLearnMore(true)}
              >
                Learn More
              </button>
            </div>
          </div>
          {sendingCertificates && (
            <div className="form-group mt-2 mb-2">
              <div className="d-flex toggle-switch">
                <label htmlFor="challenge_certificate_for_all_submissions" className="form-check-label">
                  Send certificates for all proofs?
                </label>
                <ToggleSwitch
                  id="challenge_certificates_for_all_submissions"
                  name="challenge[certificates_for_all_submissions]"
                  checked={certificatesForAll}
                  onLabel="All Proofs"
                  offLabel="Only send to the first-place winners"
                  onChange={(checked) => setCertificatesForAll(checked)}
                  disabled={!canUpdateChallenge}
                />
              </div>
            </div>
          )}
          <small className="form-text text-muted">
            A personalized digital certificate prize will be sent, depending on your choice,
            to all participants who complete the Challenge or to only the winning groups at
            the cost of $0.95 per participant certificate. You will be notified via email
            once the Challenge is completed for payment.
          </small>
        </div>

        <div className="form-group">
          <MediaUploader
            selectedLabel={(mediaType) => `Prize ${mediaType}`}
            selectLabel="Upload an Image or Video For The Prize"
            name="challenge[prize_media]"
            id="challenge_prize_media"
            mediaTypeFieldName="challenge[prize_media_type]"
            environment={environment}
            mediaUploadParams={prizeMediaUploadParams}
            mediaUploadSignature={prizeMediaUploadSignature}
            processedMediaData={props.challenge.prizeMedia}
            mediaType={props.challenge.prizeMediaType}
            defaultProcessedMediaData={
              sendingCertificates ? exampleCertificateMedia : null
            }
            defaultMediaType={
              sendingCertificates ? exampleCertificateMediaType : null
            }
            defaultMedia={defaultMedia}
            disabled={!canUpdateChallenge}
          />
          <div className="invalid-feedback">
            Please select the prize image to upload.
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="challenge_prize">What prize will be won?</label>
          <textarea
            id="prize"
            name="challenge[prize]"
            className="form-control"
            value={prize || ""}
            onChange={(event) => {
              if (event.target.value) {
                setPrize(event.target.value);
              } else {
                setPrize(null);
              }
            }}
            disabled={!canUpdateChallenge}
            required
          />
          <div className="invalid-feedback">
            Please describe the prize that will be won.
          </div>
        </div>

        <div className="form-group form-buttons">
          <button type="submit" className="btn btn-primary" disabled={submitting}>
            <i className="fas fa-users" />
            {props.challenge.id ? "Update" : "Create a New"} Challenge
          </button>
        </div>
      </ValidatableForm>

      <Modal
        isOpen={showLearnMore}
        toggle={() => setShowLearnMore(false)}
        fade={false}
        centered
        size="lg"
      >
        <ModalHeader toggle={() => setShowLearnMore(false)}>
          Challenge Completion Certificates
        </ModalHeader>
        <ModalBody>
          <p>
            You can choose to send people who complete your Challenge a
            personalized digital certificate, suitable for framing directly to
            their email address. The certificates commemorate their success and
            encourage more participation in the future.
          </p>

          <p>
            The personalized digital certificate includes your member’s name,
            Challenge name, completion date, and the Challenge URL showing your
            member’s participation in the actual Challenge. This URL
            verification helps to certify their participation for their record
            keeping. The leader’s name is automatically entered in script type
            along with Dr. Glenn Thomas’s signature that authenticates the
            certificate as an original, valid, and Flockittome.com copyrighted
            certificate of accomplishment.
          </p>

          <p>
            Certificates cost $0.95 for each member who completes the Challenge,
            and are billed for after the Challenge finishes. You will receive an
            email with the total cost and a link to our website where you can
            complete your purchase. Once payment is received, we will send out
            the certificates for you.
          </p>

          <div className="text-center text-secondary">Example Certificate</div>
          <img
            src={exampleCertificateMedia[":original"][0].ssl_url}
            className="w-100"
            alt="Example Certificate"
          />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              setSendingCertificates(false);
              setShowLearnMore(false);
            }}
          >
            Not Now
          </button>
          <button
            type="button"
            className="btn btn-success btn-lg"
            onClick={() => {
              setSendingCertificates(true);
              setShowLearnMore(false);
            }}
          >
            Yes, Please Send Completion Certificates
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

ChallengeForm.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  challenge: PropTypes.exact(CHALLENGE_PROP_TYPES).isRequired,
  exampleCertificateMedia: PropTypes.object.isRequired,
  exampleCertificateMediaType: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  mediaUploadParams: PropTypes.object.isRequired,
  mediaUploadSignature: PropTypes.string.isRequired,
  prizeMediaUploadParams: PropTypes.object.isRequired,
  prizeMediaUploadSignature: PropTypes.string.isRequired,
  defaultMedia: PropTypes.object.isRequired,
  currentUserIsAdmin: PropTypes.string.isRequired
};

ChallengeForm.defaultProps = {};

export default ChallengeForm;
